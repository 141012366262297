import React, { useEffect, useState, useContext } from "react";
import { Form, FormGroup, Input, Row, Col, Button, FormFeedback } from "reactstrap";
import Phone from "@iconify/icons-clarity/phone-handset-line";
import IEmail from "@iconify/icons-clarity/email-line";
import "assets/css/popup_contacto.css";
import { Icon } from "@iconify/react";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import ContactoContext from "context/context-contacto";
import LanguageContext from "context/context-language";

function Contacto() {
  const { language, handleLanguage } = useContext(LanguageContext);
  const [nombre, setNombre] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [validNombre, setValidNombre] = useState(true);
  const [validCorre, setValidCorreo] = useState(true);
  const [validTelefono, setValidTelefono] = useState(true);
  const [errorC, setErrorC] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [res, setRes] = useState(false);

  const [state, setState] = useState({
    valid_nombre: true,
    valid_correo: true,
    valid_correoFormato: true,
    error_correo: "",
    valid_telefono: true,
    nombre: "",
    correo: "",
    telefono: "",
    descripcion: ""
  });  

  const { openModal, HandleContacto } = useContext(ContactoContext);
  let texts = {
    es: [
      "Queremos saber de ti",
      "Llena el siguiente formulario o contáctanos por WhatsApp",
      "Nombre*",
      "Empresa",
      "Correo*",
      "Número de teléfono*",
      "Describe tu proyecto...",
      "Enviar",
      "Información enviada exitosamente",
    ],
    en: [
      "We want to hear from you",
      "Fill out the following form or contact us by WhatsApp",
      "Name*",
      "Company",
      "Email*",
      "Phone number*",
      "Describe your project...",
      "Send",
      "Information sent successfully",
    ],
  };

  let textErrors = {
    es: ["Nombre requerido", "Correo requerido", "Formato de correo incorecto", "Teléfono requerido"],
    en: ["Name required", "Email required", "Incorrect email format", "Telephone required"],
  };
  const validateEmail = (value) => {
    let validate = value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      setState(prev => ({
        ...prev,
        valid_correoFormato: validate,
        correo: value,
        error_correo: validate ? "" : "Formato de correo incorecto"  
      }))
    
  };

  const handleKeyPress = (e) => {
    let key = e.charCode;
    if (!((key >= 48 && key <= 57) || key === 45)) {
      e.preventDefault();
    }
  };
  useEffect(() => {}, [openModal]);

  const saveData = (e) => {
    const validate_form = validate();
    console.log(validate_form)
    // if (!validate_form.includes(false)) {
    //   setIsLoading(true);
    //   axios
    //     .post(
    //       //'https://dummy-dot-softel-net.uc.r.appspot.com/api/v1/email/',
    //       "https://contacs-dot-softel-net.uc.r.appspot.com/api/v1/email/",
    //       {
    //         nombre: nombre,
    //         empresa: empresa,
    //         correo: correo,
    //         telefono: telefono,
    //         descripcion: descripcion,
    //       }
    //     )
    //     .then((res) => {
    //       setRes(true);
    //       setTimeout(() => {
    //         clearForm();
    //       }, 4000);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  };

  const validate = () => {
    let arr = [validForm.nombre, validForm.correo, validForm.correoFormato, validForm.telefono];

    setState(prev => ({
      ...prev,
      valid_nombre: state.nombre === "" ? false : true,
      valid_correo: state.correo === "" ? false : true,
      valid_telefono: state.telefono === "" ? false : true
    }))

    // const arr = [];
    // if (nombre === "" || nombre === undefined) {
    //   arr.push(false);
    //   setValidNombre(false);
    // } else {
    //   setValidNombre(true);
    // }
    // if (correo === "" || correo === undefined || errorC !== undefined) {
    //   arr.push(false);
    //   setValidCorreo(false);
    // } else {
    //   if (errorC === "Formato de correo incorrecto") {
    //     setValidCorreo(false);
    //   } else {
    //     setValidCorreo(false);
    //     setErrorC(undefined);
    //   }
    // }
    // if (telefono === "" || telefono === undefined) {
    //   arr.push(false);
    //   setValidTelefono(false);
    // } else {
    //   setValidTelefono(true);
    // }
    // return arr;
  };

  const clearForm = () => {
    setNombre("");
    setEmpresa("");
    setCorreo("");
    setTelefono("");
    setDescripcion("");
    setValidNombre(true);
    setValidCorreo(true);
    setValidTelefono(true);
    HandleContacto(false);
  };

  return (
    <>
      {openModal && (
        <div className="container-popup-contacto">
          <div
            onClick={() => {
              clearForm();
            }}
            style={{ position: "absolute", right: "10px", top: "5px", color: "red", cursor: "pointer", zIndex: "10" }}
          >
            X
          </div>
          <div
            style={{ position: "relative", width: "100%", textAlign: "center", color: "#004A88", marginBottom: "15px" }}
          >
            {texts[language][0]}
          </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              textAlign: "center",
              color: "#63656A",
              fontWeight: "normal",
              fontSize: "13px",
              marginBottom: "15px",
            }}
          >
            {texts[language][1]}
            <a href="http://wa.me/+525579084631" target={"_blank"} rel="noreferrer">
              <img
                src={require("../../assets/img/WA.png")}
                style={{ width: "20px", marginLeft: "5px" }}
                alt="whatssapp"
              />
            </a>
          </div>
          <Row md="2" sm="1" xs="1">
            <Col style={{ padding: "0" }}>
              <FormGroup style={{ background: "transparent", marginRight: "5px" }}>
                <Input
                  placeholder={texts[language][2]}
                  onChange={(e) => {
                    setState(prev => ({
                      ...prev,
                      valid_nombre: e.target.value !== "" ? true : false,
                      nombre: e.target.value
                    }))                    
                  }}
                  name="nombre"
                  invalid={!state.valid_nombre}
                />
                <FormFeedback>{textErrors[language][0]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col style={{ padding: "0" }}>
              <FormGroup style={{ background: "transparent", marginLeft: "5px" }}>
                <Input placeholder={texts[language][3]} onChange={(e) => setEmpresa(e.target.value)} name="empresa" />
              </FormGroup>
            </Col>
          </Row>
          <Row md="2" sm="1" xs="1">
            <Col style={{ padding: "0" }}>
              <FormGroup style={{ background: "transparent", marginRight: "5px" }}>
                <Input
                  placeholder={texts[language][4]}
                  onChange={(e) => {
                    setState(prev => ({
                      ...prev,
                      valid_correo: e.target.value !== "" ? true : false,
                      correo: e.target.value
                    }))
                    if(e.target.value !== ""){
                      validateEmail(e.target.value);
                    }
                    
                  }}
                  name="correo"
                  invalid={ state.valid_correo ===false && state.valid_correoFormato === false ? true : false }                  
                  type="email"
                />
                <FormFeedback>
                  {correo === "" ? <>{textErrors[language][1]}</> : <>{textErrors[language][2]}</>}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col style={{ padding: "0" }}>
              <FormGroup style={{ background: "transparent", marginLeft: "5px" }}>
                <Input
                  placeholder={texts[language][5]}
                  onChange={(e) => {
                    setState(prev => ({
                      ...prev,
                      telefono: e.target.value,
                      valid_telefono: e.target.value !== "" ? true : false                      
                    }))                    
                  }}
                  name="telefono"
                  invalid={!state.valid_telefono}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                <FormFeedback>{textErrors[language][3]}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: "0" }}>
              <Input
                type="textarea"
                placeholder={texts[language][6]}
                style={{ height: "130px" }}
                onChange={(e) => setDescripcion(e.target.value)}
                name="descripcion"
              />
            </Col>
          </Row>
          <div style={{ position: "relative", width: "100%", display: "flex", top: "20px" }}>
            <div
              style={{
                position: "relative",
                width: "calc(100% - 80px)",
                fontSize: "11px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                color: "#63656A",
              }}
              className="footre-contacto"
            >
              <div style={{ position: "relative", width: "100%", display: "flex" }}>
                <div style={{ marginRight: "10px" }}>
                  <a href="http://wa.me/+525579084631" target={"_blank"} rel="noreferrer">
                    <Icon icon={Phone} style={{ marginRight: "5px" }} />
                    55 5447 1040{" "}
                  </a>
                </div>
                <div>
                  {" "}
                  <a href="mailto:sales@softel.mx">
                    <Icon icon={IEmail} style={{ marginLeft: "5px", marginRight: "5px" }} />
                    sales@softel.mx
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                width: "80px",
                fontSize: "14px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                color: "#63656A",
              }}
            >
              {isLoading === true ? (
                <PuffLoader color="#36d7b7" size={30} />
              ) : (
                <div
                  style={{
                    position: "relative",
                    width: "70px",
                    textAlign: "center",
                    color: "#004A88",
                    marginLeft: "5px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={(e) => saveData(e)}
                >
                  {texts[language][7]}
                </div>
              )}
            </div>
          </div>
          {res && (
            <div
              style={{
                position: "relative",
                width: "100%",
                fontWeight: "normal",
                marginTop: "30px",
                fontSize: "12px",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px",
                backgroundColor: "#d4edda",
                color: "#155724",
                borderColor: "#c3e6cb",
              }}
            >
              {texts[language][8]}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Contacto;
