import React, { useContext } from "react";
import LanguageContext from "context/context-language";
import "assets/css/medidores.css";

let textos = {
    es: [
        {
            seccion1: [
                "Medidores para tu obra inmobiliaria",
                ["Las obras inmobiliarias requieren de una infraestructura y equipamiento eléctrico que cumpla con las certificaciones dictadas por CFE.", "Las nuevas normas para medidores IoT buscan implementar soluciones que garanticen la interacción remota con los medidores eléctricos, trayendo mejoras operativas para CFE y beneficios a constructores en ahorros de infraestructura, mayor plusvalía y mejora de seguridad.", "La solución Wasion-Softel para medidores eléctricos ha sido validada por CFE y cumple con las nuevas normas de telemedición."],
                "Ofrecemos",
                ["Medidores monofásicos y bifásicos", "Medidores industriales"],
                "Incluye comunicación y monitoreo por 10 años",
            ]
        },
        {
            seccion2: [
                "Medidores soportados",
                "Libra 1C",
                "Medidor para aplicaciones Monofásicas y Bifásicas",
                ["Tarjeta de comunicación en malla (Cumple NOM. GWH00-79)", "10 años de comunicación", "Plataforma de monitoreo"],
                "Libra 3C",
                "Medidor para aplicaciones Trifásicas (Industrial)",
                ["Comunicación LTE-M", "Acceso a perfiles de carga por 10 años", "Plataforma de monitoreo"]
            ]
        }
    ], 
    en: [
        {
            seccion1: [
                "Meters for Your Real Estate Project",
                ["Real estate projects require electrical infrastructure and equipment that comply with certifications mandated by CFE (Federal Electricity Commission) in México.", "The new standards for IoT meters aim to implement solutions that ensure remote interaction with electrical meters, bringing operational improvements for CFE and benefits to builders in terms of infrastructure savings, increased property value, and improved safety.", "The Wasion-Softel solution for electrical meters has been validated by CFE and complies with the new remote metering standards."],
                "We offer",
                ["Single-phase and two-phase meters", "Industrial meters"],
                "Includes communication and monitoring for 10 years",
            ]
        },
        {
            seccion2: [
                "Supported meters",
                "Libra 1C",
                "Meter for single-phase and two-phase applications",
                ["Mesh communication card (Complies with NOM GWH00-79)", "10 years of communication", "Monitoring platform"],
                "Libra 3C",
                "Meter for three-phase applications (Industrial)",
                ["LTE-M communication", "Access to load profiles for 10 years", "Monitoring platform"]
            ]
        }
    ]
}
function Medidores(){
    const { language, handleLanguage } = useContext(LanguageContext);  
    let info = textos[language];
    let seccion1 = info[0].seccion1;
    let seccion2 = info[1].seccion2;
    
    
    return(
        <>
            <div className="card-detalle card-detalle-med">
                <div className='img1-r'>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundImage: "url(" + require('../assets/img/servicios/medidores_iot_wasion_softel/WasionSoftel_Horizontal.png') + ")",
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',                                            
                            position: 'relative',
                            width: '100%',
                            marginLeft: '10px',
                            WebkitBorderRadius: '10px',  
                            marginTop: '0',                                            
                        }}
                        className="img1Med"
                    ></div>
                </div>
                <div className='text1'>
                    <div>
                        <div style={{background: 'transparent', fontWeight: 'bold', color: '#4D4D4D', fontSize: '20px', marginBottom: '20px'}}>{seccion1[0]}</div>
                        <div style={{background: 'transparent', color: '#63656A', marginBottom: '10px', fontSize: '15px'}}>
                            {seccion1[1][0]}<br/><br/>
                            {seccion1[1][1]}<br/><br/>
                            {seccion1[1][2]}
                        </div>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion1[2]}</div>
                        <div>
                            {
                                seccion1[3].map(item => {
                                    return(
                                        <>
                                            <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{item}</li>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginBottom: '10px', fontSize: '17px', textDecoration: 'underline'}}>{seccion1[4]}</div>
                    </div>
                </div>
               
                <div className='img1'>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundImage: "url(" + require('../assets/img/servicios/medidores_iot_wasion_softel/WasionSoftel_Vertical.png') + ")",
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',                                            
                            position: 'relative',
                            width: '100%',
                            marginLeft: '10px',
                            WebkitBorderRadius: '10px',  
                            marginTop: '0',                                            
                        }}
                        className="img1Med"
                    ></div>
                </div>
            </div>
            <div className="card-detalle-m" style={{display: 'block'}}> 
                <div style={{background: 'transparent', fontWeight: 'bold', color: '#4D4D4D', fontSize: '20px', marginBottom: '20px'}}>{seccion2[0]}</div>
                <div className='seccion2'>
                    <div className='seccion2-1'>
                        <div style={{width: '100%', height: '100%'}}>                        
                            <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginBottom: '10px', fontSize: '17px'}}>{seccion2[1]}</div>
                            <div style={{background: 'transparent', color: '#63656A', marginBottom: '10px', fontSize: '15px'}}>{seccion2[2]}</div>
                            <div>
                                {
                                    seccion2[3].map(item => {
                                        return(
                                            <>
                                                <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{item}</li>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div style={{width: '100%', height: '50%'}}>
                                <div
                                    style={{
                                        alignItems: 'center',
                                        backgroundImage: "url(" + require('../assets/img/servicios/medidores_iot_wasion_softel/WasionSoftel_1C.png') + ")",
                                        backgroundSize: 'cover',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: 'center',
                                        backgroundColor: 'transparent',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        height: '300px',
                                        justifyContent: 'center',                                            
                                        position: 'relative',
                                        width: '100%',                                
                                        WebkitBorderRadius: '10px',  
                                        marginTop: '0',                                            
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className='seccion2-2'>
                        <div style={{width: '100%', height: '100%'}}>
                            <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginBottom: '10px', fontSize: '17px'}}>{seccion2[4]}</div>
                            <div style={{background: 'transparent', color: '#63656A', marginBottom: '10px', fontSize: '15px'}}>{seccion2[5]}</div>
                            <div>
                                {
                                    seccion2[6].map(item => {
                                        return(
                                            <>
                                                <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{item}</li>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div style={{width: '100%', height: '50%'}}>
                                <div
                                    style={{
                                        alignItems: 'center',
                                        backgroundImage: "url(" + require('../assets/img/servicios/medidores_iot_wasion_softel/WasionSoftel_3C.png') + ")",
                                        backgroundSize: 'cover',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: 'center',
                                        backgroundColor: 'transparent',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        height: '300px',
                                        justifyContent: 'center',                                            
                                        position: 'relative',
                                        width: '100%',                                    
                                        WebkitBorderRadius: '10px',  
                                        marginTop: '0',                                            
                                    }}
                                ></div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default Medidores;