import React, {useContext} from "react";
import "../assets/css/planes.css";
import ContactoContext from "../context/context-contacto";
import Contacto from "../components/contacto/contacto";
import Footer from "components/Footer/Footer1";
import LanguageContext from "context/context-language";

function planes(){
    const { openModal, HandleContacto } = useContext(ContactoContext);
    const { language, handleLanguage } = useContext(LanguageContext);  
    let textos = {
        es: ["Conoce nuestros Planes de Datos", "Contactanos"],
        en: ["Discover our Data Plans", "Contact us"]
    }
    return(
        <>
            <div className="container_planes">
                <div className="back-img">
                    <div style={{position: 'relative', width: '100%', background: 'transparent'}}>
                        <div style={{textAlign: 'center', background: 'transparent', color: '#fff', fontWeight: 'bold', fontSize: '40px'}}>{textos[language][0]}</div>
                        <div className="arrowCont"></div>
                        <div className="btn-contact-c" style={{left: 'calc(50% - 50px)', marginBottom: '20px'}} onClick={() => HandleContacto(true)}>
                            {textos[language][1]}
                        </div>
                    </div>                    
                </div>
                <Footer/>                    
            </div>
            
            <Contacto/>
            
        </>
    )
}

export default planes;