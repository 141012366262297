import React, { createRef, useEffect, useRef, useState, useContext } from "react";
import "assets/css/casos_exito.css";
import var_casos_exito from "variables/casos_exito";
import useIsVisible from "./useIntersection";
import ContactoContext from "context/context-contacto";
import LanguageContext from "context/context-language";

function Casos_exito() {
  const { language, handleLanguage } = useContext(LanguageContext);
  const [inView, setInView] = useState(() =>
    var_casos_exito[language].map((item, index) => (index === 0 ? true : false))
  );
  const { openModal, HandleContacto } = useContext(ContactoContext);
  const elementsRef = useRef(var_casos_exito[language].map(() => createRef()));

  let texts = {
    es: ["Casos de Éxito", "Contáctanos"],
    en: ["Success Stories", "Contact us"],
  };

  const selectItemMenu = (id) => {
    const element = document.getElementById("card" + id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });

    const newInView = [...inView];
    for (const i in newInView) {
      if (parseInt(i) === id) {
        newInView[i] = true;
      } else {
        newInView[i] = false;
      }
    }
    setInView(newInView);
  };
  const isVisible0 = useIsVisible(elementsRef.current[0]);
  const isVisible1 = useIsVisible(elementsRef.current[1]);
  const isVisible2 = useIsVisible(elementsRef.current[2]);
  const isVisible3 = useIsVisible(elementsRef.current[3]);
  const isVisible4 = useIsVisible(elementsRef.current[4]);

  const onScroll = () => {
    let newInView = [...inView];
    if (isVisible0) {
      newInView = [true, false, false, false, false];
    } else if (isVisible1) {
      newInView = [false, true, false, false, false];
    } else if (isVisible2) {
      newInView = [false, false, true, false, false];
    } else if (isVisible3) {
      newInView = [false, false, false, true, false];
    } else if (isVisible4) {
      newInView = [false, false, false, false, true];
    }

    setInView(newInView);
  };

  return (
    <>
      <div className="container_casosExito">
        <div className="cardCasosExito">
          <div className="tituloCasosExito">{texts[language][0]}</div>
          <div className="containerCasosExito">
            <div className="casosExito-menu">
              <ul>
                {var_casos_exito[language].map((item, index) => {
                  return (
                    <li
                      className={`${inView[index] === true ? "li-active" : "li-menu "}`}
                      onClick={() => selectItemMenu(index)}
                    >
                      <div className={`text-menu ${inView[index] === true ? "text-menu-active" : ""}`}>
                        {item.titulo}
                      </div>
                      <div className={`icon-arrow ${inView[index] === true ? "icon-arrow-active" : ""}`}></div>
                    </li>
                  );
                })}
              </ul>
              <div className="btn-contactC" onClick={() => HandleContacto(true)}>
                {texts[language][1]}
              </div>
            </div>
            <div
              className="casosExito_descripcion"
              onScroll={() => onScroll()}
              id="descripcion-casos-exito"
              tabIndex="0"
            >
              {var_casos_exito[language].map((item, index) => {
                return (
                  <>
                    <div
                      className="card-caso-exito"
                      id={"card" + index}
                      ref={elementsRef.current[index]}
                      style={{ position: "relative", width: "100%", display: "flex" }}
                    >
                      <div className="casoExito_text">
                        <div className="title-caso-exito">{item.titulo}</div>
                        <div className="text-caso-exito">
                          {item.descripcion[0]}
                          <br />
                          <br />
                          {item.descripcion[1]}
                        </div>
                      </div>
                      <div
                        className="casoExito_img"
                        style={{
                          alignItems: "center",
                          backgroundImage: "url(" + item.img + ")",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundColor: "transparent",
                          borderRadius: "10px",
                          display: "flex",
                          height: "100%",
                          justifyContent: "center",
                          position: "relative",
                          width: "50%",
                          marginLeft: "10px",
                          WebkitBorderRadius: "10px",
                          marginTop: "0",
                        }}
                      ></div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Casos_exito;
