import React, { createContext, useEffect, useState } from "react";

const LanguageContext = createContext();

//const languageDefault = 'ES';

const LanguageProvider = ({ children, languageDefault }) => {
    const [language, setLanguage] = useState('es');    

    useEffect(() => {
        let userLang = navigator.language || navigator.userLanguage; 
        let split = userLang.split('-')[0];    
        
        if(localStorage.getItem('languageID')){
            setLanguage(localStorage.getItem('languageID'));
        }else{
            if(split === 'es' || split === 'en'){
                setLanguage(split);        
                localStorage.setItem('languageID', split);
            }else{
                setLanguage('en');        
                languageID            }
            
        }
    }, []);
        
    //localStorage.setItem('language', languageDefault);
    const handleLanguage = (value) => {        
        setLanguage(value);        
        localStorage.setItem('languageID', value);
    }

    const data = { language, handleLanguage };

    return (
        <LanguageContext.Provider value={data}>{children}</LanguageContext.Provider>
    );
}
export { LanguageProvider };
export default LanguageContext;