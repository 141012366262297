import React, { createContext, useState } from "react";

const ContactoContext = createContext();

const ContactProvider = ({ children }) => {
    const [openModal, setOpenModal] = useState(false);

    const HandleContacto = (value) => {        
        setOpenModal(value)
    }

    const data = { openModal, HandleContacto };

    return (
        <ContactoContext.Provider value={data}>{children}</ContactoContext.Provider>
    );
}
export { ContactProvider };
export default ContactoContext;