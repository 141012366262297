const casos_exito = {
    es: [
        {
            titulo: "Industria Eléctrica",
            descripcion: [
                "Conectamos al día de hoy más de 100 mil medidores, permitiendo a CFE realizar las lecturas de manera remota y hacer más eficiente la facturación al usuario final.",
                "Cumplimos con el nuevo estándar IoT de la CFE en nuestras tarjetas de comunicación que han sido aprobadas para medidores tipo socket (034), media tensión y gabinetes."
            ],        
            img: require('../assets/img/casos_exito/IndustriaElectrica_CasosExito.png')
        },
        {
            titulo: "Industria de Refrigeración",
            descripcion: [
                "A lo largo de los últimos 10 años, hemos diseñado y entregado más de 2 millones de controles al principal fabricante de refrigeradores en México. Contribuyendo a su evolución y llevándolos al mundo del IoT.",
                "Nuestros controles con comunicación Bluetooth, WiFi y Wi-SUN, mejoran la eficiencia energética y generan ahorros en mantenimiento."
            ],
            img: require('../assets/img/casos_exito/CE_Ind_Refrigeración.png')
        },
        {
            titulo: "Industria Bancaria",
            descripcion: [
                "Hemos conectado mediante enlaces de comunicación vía celular más de 5,000 Cajeros Automáticos (fuera de sucursal) de los principales bancos de México.",
                "Garantizamos una disponibilidad del 99% y ofrecemos niveles de servicios para atención en menos de 5 horas, monitoreo y atención 24/7 a través de nuestra Mesa de Ayuda."
            ],
            img: require('../assets/img/casos_exito/IndustriaBancaria_CasosExito.png')
        },
        {
            titulo: "Prevención de Cajeros",
            descripcion: [
                "En el último año, gracias a la detección oportuna se atendieron y combatieron dos actos de vandalismo en Cajeros Automáticos evitando pérdidas de 2 millones de pesos mexicanos que estos actos pudieron generar.",
                "Nuestra solución SOFTEL Net GUARD facilita la toma de decisión para llevar a cabo acciones precisas."
            ],
            img: require('../assets/img/casos_exito/CE_Prev_Cajero.png')
        },
        {
            titulo: "Diseño Electrónico",
            descripcion: [
                "Hemos brindado servicios de diseño para empresas en Alemania: WIS, USA: Phillips, Wittern, entre otras.",
                "Trabajando en conjunto con sus áreas de diseño e ingeniería para crear productos IoT con tecnologías CAN, Bluetooth, WIFI, etc. Desde su conceptualización hasta su industrialización."
            ],
            img: require('../assets/img/casos_exito/DiseñoElectronico_CasosExito.png')
        }    
    ],
    en: [
        {
            titulo: "Electrical Industry",
            descripcion: [
                "Today, we connect over 100,000 meters, enabling CFE (Federal Electricity Commission in Mexico) to remotely read the meters and make the billing process more efficient for end users.",
                "We comply with CFE's new IoT standard on our network interface cards, which have been approved for low voltage meters (034), medium voltage, and cabinets."
            ],        
            img: require('../assets/img/casos_exito/IndustriaElectrica_CasosExito.png')
        },
        {
            titulo: "Refrigeration Industry",
            descripcion: [
                "Over the past 10 years, we have designed and delivered over 2 million controls to the leading refrigerator manufacturer in Mexico, contributing to their innovation and bringing them into the world of IoT.",
                "Our controls with Bluetooth, WiFi, and Wi-SUN communication enhance energy efficiency and generate savings in maintenance."
            ],
            img: require('../assets/img/casos_exito/CE_Ind_Refrigeración.png')
        },
        {
            titulo: "Banking Industry",
            descripcion: [
                "We have connected over 5,000 off-site Automated Teller Machines (ATMs) from major banks in Mexico using cellular communication links.",
                "We guarantee a 99% availability rate and provide service levels for support within 5 hours, 24/7 monitoring, and assistance through our Help Desk."
            ],
            img: require('../assets/img/casos_exito/IndustriaBancaria_CasosExito.png')
        },
        {
            titulo: "ATM Security",
            descripcion: [
                "In the past year, thanks to timely detection, we were able to address and combat two acts of vandalism on ATMs, preventing potential losses of 2 million mexican pesos.",
                "Our solution, SOFTEL Net GUARD, facilitates decision-making in critical events to carry out precise actions."
            ],
            img: require('../assets/img/casos_exito/CE_Prev_Cajero.png')
        },
        {
            titulo: "Electronic Design",
            descripcion: [
                "We have provided design services for companies in Germany such as WIS, and in the USA such as Phillips and Wittern, among others.",
                "Working closely with their design and engineering departments, we create IoT products using technologies such as CAN, Bluetooth, WiFi, etc., from conceptualization to industrialization."
            ],
            img: require('../assets/img/casos_exito/DiseñoElectronico_CasosExito.png')
        }    
    ]
};

export default casos_exito;