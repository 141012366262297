import React, { useState, useContext, useEffect } from "react";
import "assets/css/carousel.css";
import var_carousel from "variables/carousel";
import { NavLink } from "react-router-dom";
import ContactoContext from "context/context-contacto";
import LanguageContext from "context/context-language";
import { Icon } from "@iconify/react";
import Back from "@iconify/icons-ic/arrow-back";
import Next from "@iconify/icons-ic/arrow-forward";

function Carousel() {
  const { language, handleLanguage } = useContext(LanguageContext);
  const background = ["card-carousel", "card-carousel2"];
  const [cardActive, setCardActive] = useState(0);
  const [responsive, setResponsive] = useState(false);
  const { openModal, HandleContacto } = useContext(ContactoContext);

  let textBtn = {
    es: ["Saber Más", "Contacto"],
    en: ["Know more", "Contact"],
  };

  const back = () => {
    if (cardActive - 1 < 0) {
      setCardActive(var_carousel[language].length - 1);
    } else {
      setCardActive(cardActive - 1);
    }
  };

  const next = async () => {
    if (cardActive + 1 > var_carousel[language].length - 1) {
      await setCardActive(0);
    } else {
      await setCardActive(cardActive + 1);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      next();
    }, 5000);

    return () => clearInterval(interval);
  }, [cardActive]);

  return (
    <>
      <div className="container-carousel">
        <div className="back-carousel" onClick={() => back()}>
          <Icon icon={Back} />
        </div>
        {var_carousel[language].map((item, index) => {
          return (
            <>
              <div
                className={background[index]}
                style={{
                  opacity: cardActive === index ? "1" : "0",
                  visibility: cardActive === index ? "visible" : "hidden",
                  WebkitTransition: "opacity 600ms, visibility 600ms",
                  transition: "opacity 600ms, visibility 600ms",
                }}
              >
                <div className="carousel-text">
                  <div>
                    <div className="carousel-text-titulo">{item.titulo}</div>
                    <div className="carousel-text-descripcion">{item.descripcion[0]}</div>
                    <div className="carousel-text-subdescripcion">{item.descripcion[1]}</div>
                    <div className="carousel-text-btns">
                      <NavLink to={item.link} style={{ background: "transparent", textDecoration: "none" }}>
                        <div className="btn-more">{textBtn[language][0]}</div>
                      </NavLink>

                      <div className="btn-contact" onClick={() => HandleContacto(true)}>
                        {textBtn[language][1]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-img">
                  <img src={item.img} alt={item.titulo} />
                  <div className="gradient">
                    <div className="gradient-text-titulo">{item.titulo}</div>
                    <div className="gradient-text-descripcion">{item.descripcion[0]}</div>
                    <div className="gradient-text-subdescripcion">{item.descripcion[1]}</div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <div className="next-carousel" onClick={() => next()}>
          <Icon icon={Next} />
        </div>
      </div>
    </>
  );
}

export default Carousel;
