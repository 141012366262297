import React from "react";
import * as L from "leaflet";
import iconS from "../../assets/img/puntero.png";
import 'leaflet/dist/leaflet.css'; 


class Mapa extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            position: [51.505, -0.09]
        };
    }

    componentDidMount() {
        // create map
        this.map = L.map("map", {
            center: [19.2969608, -99.1917667],
            zoom: 18, 
            layers: [
                L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                    attribution:
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                })
            ],
           /*  fullscreenControl: true,
            fullscreenControlOptions: { // optional
                title:"Show me the fullscreen !",
                titleCancel:"Exit fullscreen mode"
            }, */
            markersRef: {}
        });   
        let icon = L.icon({
            iconUrl: iconS,
            iconSize: [35,35]
        });
        this.layer = L.layerGroup().addTo(this.map);    
        L.marker({lat:19.2969608, lng: -99.1917667}, {icon: icon}).addTo(this.map)
    }
    render() {    
        return (
            <>        
            <div id="map" className="containerMapLeaflet" />
            </>
        );
    }
    
}


export default Mapa;