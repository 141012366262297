const videos = {
    comunicacion_mesh_y_celular_para_medidores_electricos: <iframe width="60%" height="auto" src="https://www.youtube.com/embed/K_IX8wbqPGs?autoplay=1&playlist=K_IX8wbqPGs&loop=1&mute=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="videoYT"></iframe>,
    comunicacion_mesh_y_celular_para_luminarioas_publicas:<iframe width="560" height="315" src="https://www.youtube.com/embed/9UHAuei5Ees?autoplay=1&playlist=9UHAuei5Ees&loop=1&mute=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="videoYT"></iframe>,
    monitoreo_de_productos_refrigerados:<iframe width="560" height="315" src="https://www.youtube.com/embed/csdkLP24BSM?autoplay=1&playlist=csdkLP24BSM&loop=1&mute=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="videoYT"></iframe>,
    monitoreo_de_activos_indoor: <iframe width="560" height="315" src="https://www.youtube.com/embed/bwvgw9-sPBk?autoplay=1&playlist=bwvgw9-sPBk&loop=1&mute=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="videoYT"></iframe>,
    enlaces_monitoreados_de_comunicacion_via_celular: <iframe width="560" height="315" src="https://www.youtube.com/embed/h4WsxebZ_h8?autoplay=1&playlist=h4WsxebZ_h8&loop=1&mute=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="videoYT"></iframe>,
    monitore_entornos_atms: <iframe width="560" height="315" src="https://www.youtube.com/embed/ASbdf-Z0c6M?autoplay=1&playlist=ASbdf-Z0c6M&loop=1&mute=true" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="videoYT"></iframe>,
    red_mesh_para_tus_dispositivos_IoT: <video style={{width: '60%'}} autoPlay loop controls muted><source src={require('../assets/img/servicios/servicios_iot/descripcion.m4v')} type="video/mp4"/>Your browser does not support the video tag.</video>    ,
    servicios_diseño: <video style={{width: '60%'}} autoPlay loop controls muted><source src={require('../assets/img/productos/servicios_diseño/things.m4v')} type="video/mp4"/>Your browser does not support the video tag.</video>    ,
    
}

export default videos;