import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import "assets/css/detalle.css";
import textos_detalle_es from "variables/detalle";
import textos_detalle_en from "variables/detalle_en";
import { Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import doc_softelNet from "assets/docs/IoT GWH00-34 Electric Meter NIC GWH00-79.pdf";
import videos from "variables/videos";
import { Icon } from "@iconify/react";
import Download from "@iconify/icons-ic/round-download";
import Contacto from "components/contacto/contacto";
import ContactoContext from "context/context-contacto";
import Medidores from "layouts/medidores";
import Control from "layouts/control";
import Footer from "components/Footer/footerDetalle";
import Servicios_diseño from "layouts/servicios_diseño";
import Control_refrigeracion_imbera from "./contro_refrigeracion_imbera";
import Planes from "./planes";
import LanguageContext from "context/context-language";

const docs = {
  doc_softelNet: doc_softelNet,
};

function Detalle() {
  const { language, handleLanguage } = useContext(LanguageContext);
  const { openModal, HandleContacto } = useContext(ContactoContext);
  const [modalGif, setModalGif] = useState(false);
  const { pathname } = useLocation();
  let split = pathname.split("/");
  let lateral = undefined;
  let tipo = undefined;
  let secciones = undefined;
  let conteindo = [];
  let carga = undefined;
  let img_principal = undefined;
  let videoSeccion = undefined;
  let descargas = undefined;

  let textosGral = {
    es: ["Contáctanos", "Descargas", "Descargar PDF", "Documentos", "App Usuario", "Conoce más"],
    en: ["Contact us", "Downloads", "Download PDF", "Documents", "App User", "Know more"],
  };

  useEffect(() => {
    console.log(split)
    const element = document.getElementById("header");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, [split[2]]);

  let var_detalle = undefined;
  switch (language) {
    case "es":
      var_detalle = textos_detalle_es;
      break;
    case "en":
      var_detalle = textos_detalle_en;
      break;
    default:
      break;
  }

  let info = var_detalle[split[1]][split[2]];
  if (split[2] !== "controles_refrigeracion_IMBERA" && split[2] !== "planes_datos") {
    lateral = info.lateral;
    conteindo = info.contenido;
    descargas = info.descargas;
    if (videos[split[2]]) {
      videoSeccion = videos[split[2]];
    }
    secciones = conteindo?.secciones;

    switch (split[2]) {
      case "medidores_iot_wasion_softel":
        carga = 1;
        break;
      case "control_refrigeracion_easy_cool":
        carga = 2;
        break;
      case "servicios_diseño":
        carga = 4;
        break;
      default:
        carga = 3;
        break;
    }
  }

  return (
    <>
      <div id="header"></div>
      {carga !== undefined ? (
        <div className="container-detalle">
          <div className="container-lateral">
            <div className="container-lateral-r">
              <div className="icon-lateral">
                <img src={lateral.icono} alt="" />
              </div>
              <div className="titulo-lateral">{lateral.titulo}</div>
              <div className="container-btns-lateral">
                <div style={{ background: "transparent" }}>
                  <div>
                    <div className="bnt-cont" onClick={() => HandleContacto(true)}>
                      {textosGral[language][0]}
                    </div>
                    {lateral.descrgas === true && (
                      <div
                        className="bnt-desc"
                        onClick={() => {
                          const elmnt = document.getElementById("descargas");
                          elmnt.scrollIntoView({ behavior: "smooth", block: "start" });
                        }}
                      >
                        {textosGral[language][1]}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {lateral.img.length > 0 && (
                <>
                  <div className="logos">
                    <img src={lateral.img[0]} style={{ width: "80px", background: "transparent" }} alt="img" />
                    <br />
                    <img src={lateral.img[1]} style={{ width: "70px", background: "transparent" }} alt="img" />
                    <br />
                    <img src={lateral.img[2]} style={{ width: "70px", background: "transparent" }} alt="img" />
                  </div>
                </>
              )}
            </div>
            {lateral.img.length > 0 && (
              <>
                <div className="logos-r">
                  <img src={lateral.img[0]} style={{ width: "80px", background: "transparent" }} alt="img" />
                  <br />
                  <img src={lateral.img[1]} style={{ width: "70px", background: "transparent" }} alt="img" />
                  <br />
                  <img src={lateral.img[2]} style={{ width: "70px", background: "transparent" }} alt="img" />
                </div>
              </>
            )}
          </div>
          <div
            className="container-detalle-text"
            /* style={{'--top': (split[2] !== "red_mesh_para_tus_dispositivos_IoT")?'70px':'50px'}} */
          >
            {carga === 3 ? (
              <>
                <div className="card-detalle-d">
                  <div style={{ width: "100%" }}>
                    <div style={{ position: "relative", width: "100%", textAlign: "center" }}>{videoSeccion}</div>
                    <div className="description">{conteindo.descripcion}</div>
                  </div>
                </div>

                {secciones.map((item) => {
                  let newOrder1 = undefined;
                  let newOrder2 = undefined;

                  if (item.left === true) {
                    newOrder1 = { order: 1 };
                    newOrder2 = { order: 2 };
                  } else {
                    newOrder1 = { order: 2 };
                    newOrder2 = { order: 1 };
                  }

                  return (
                    <>
                      <div className="card-detalle-d">
                        <Row
                          md="2"
                          sm="1"
                          xs="1"
                          style={{
                            position: "relative",
                            width: "100%",
                            minHeight: "calc(90vh - 70px)",
                            background: "transparent",
                          }}
                        >
                          <Col
                            xs={newOrder1}
                            sm={newOrder1}
                            md={newOrder1}
                            style={{
                              background: "transparent",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ background: "transparent", fontSize: "15px" }}>
                              <img
                                src={item.icon}
                                style={{ background: "transparent", marginBottom: "30px", width: "50px" }}
                                alt="img"
                              />
                              <br />
                              <div
                                style={{
                                  background: "transparent",
                                  fontWeight: "bold",
                                  color: "#63656A",
                                  fontSize: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                {item.titulo}
                              </div>
                              <div style={{ background: "transparent", color: "#63656A", marginBottom: "10px" }}>
                                {item.subtitulo}
                              </div>
                              {item.subtitulo2 && (
                                <div style={{ background: "transparent", color: "#63656A", marginBottom: "10px" }}>
                                  {item.subtitulo2[0]}
                                  <a
                                    href={require("../assets/docs/Case-0305_Wi-SUN-Certificate-FAN-Profile.pdf")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.subtitulo2[1]}
                                  </a>
                                  {item.subtitulo2[2]}
                                  <a
                                    href={require("../assets/docs/Case-0318_Wi-SUN-Certificate-FAN-Profile.pdf")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.subtitulo2[3]}
                                  </a>
                                  {item.subtitulo2[4]}
                                </div>
                              )}
                              {item.gif && (
                                <div className="bntMas" onClick={() => setModalGif(true)}>
                                  {textosGral[language][5]}
                                </div>
                              )}
                              {item.points.map((point) => {
                                if (point.hasOwnProperty("link")) {
                                  return (
                                    <li
                                      style={{
                                        background: "transparent",
                                        color: "#63656A",
                                        listStyle: "square",
                                        borderTop: "solid 1px #63656A",
                                        padding: "8px 0 8px 0",
                                      }}
                                    >
                                      <a
                                        href={docs[point.id]}
                                        style={{ background: "transparent", color: "#63656A" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {point.link}
                                      </a>
                                      {point.text}
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li
                                      style={{
                                        background: "transparent",
                                        color: "#63656A",
                                        listStyle: "square",
                                        borderTop: "solid 1px #63656A",
                                        padding: "8px 0 8px 0",
                                      }}
                                    >
                                      {point}
                                    </li>
                                  );
                                }
                              })}
                              <div style={{ background: "transparent", color: "#63656A", marginTop: "10px" }}>
                                {item.descripcion}
                              </div>
                            </div>
                          </Col>
                          <Col
                            xs={newOrder1}
                            sm={newOrder1}
                            md={newOrder2}
                            style={{
                              background: "transparent",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="imgDetalle"
                              style={{
                                backgroundImage: "url(" + item.imagen + ")",
                              }}
                            ></div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                })}
              </>
            ) : carga === 0 ? (
              <RedMesh contenido={conteindo} />
            ) : carga === 1 ? (
              <Medidores />
            ) : carga === 2 ? (
              <Control />
            ) : carga === 4 ? (
              <Servicios_diseño />
            ) : carga === 5 ? (
              <Planes />
            ) : null}
            {lateral.descrgas === true && (
              <div className="card-detalle-d" id="descargas">
                <div style={{ position: "relative", width: "100%" }}>
                  <div
                    style={{
                      background: "transparent",
                      color: "#4D4D4D",
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginTop: "10px",
                      marginBottom: "30px",
                      borderBottom: "solid 1px #000000",
                    }}
                  >
                    {textosGral[language][1]}
                  </div>

                  {split[2] !== "control_refrigeracion_easy_cool" && (
                    <div
                      style={{
                        background: "transparent",
                        color: "#004A88",
                        fontWeight: "bold",
                        fontSize: "15px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      {textosGral[language][3]}
                    </div>
                  )}
                  <Row md="2" sm="1" xs="1" style={{ background: "transparent" }}>
                    {split[2] === "control_refrigeracion_easy_cool" ? (
                      <>
                        <Col>
                          <div
                            style={{
                              background: "transparent",
                              color: "#004A88",
                              fontWeight: "bold",
                              fontSize: "15px",
                              marginTop: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            {textosGral[language][3]}:
                          </div>
                          {descargas.map((item) => {
                            if (item.left === true) {
                              return (
                                <Row>
                                  <Col style={{ background: "transparent", marginBottom: "10px" }}>
                                    <div className="cardDownload">
                                      <div style={{ background: "transparent" }}>
                                        {item.documento === "" ? (
                                          <>
                                            <NavLink to="/pagina_construccion" style={{ textDecoration: "none" }}>
                                              <div>
                                                <span
                                                  style={{
                                                    background: "transparent",
                                                    color: "#004A88",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.titulo}
                                                </span>
                                                <br />
                                                <span
                                                  style={{
                                                    background: "transparent",
                                                    color: "#4D4D4D",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {textosGral[language][2]}
                                                </span>
                                                <br />
                                                <Icon
                                                  icon={Download}
                                                  style={{ background: "transparent", color: "#4D4D4D" }}
                                                />
                                              </div>
                                            </NavLink>
                                          </>
                                        ) : (
                                          <>
                                            <a
                                              href={item.documento}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ background: "transparent", textDecoration: "none" }}
                                            >
                                              <span
                                                style={{
                                                  background: "transparent",
                                                  color: "#004A88",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {item.titulo}
                                              </span>
                                              <br />
                                              <span
                                                style={{
                                                  background: "transparent",
                                                  color: "#4D4D4D",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {textosGral[language][2]}
                                              </span>
                                              <br />
                                              <Icon
                                                icon={Download}
                                                style={{ background: "transparent", color: "#4D4D4D" }}
                                              />
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        </Col>
                        <Col>
                          <div
                            style={{
                              background: "transparent",
                              color: "#111C4E",
                              fontWeight: "bold",
                              fontSize: "15px",
                              marginTop: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            {textosGral[language][4]}
                          </div>
                          {descargas.map((item) => {
                            if (item.left === false) {
                              return (
                                <Row>
                                  <Col style={{ background: "transparent", marginBottom: "10px" }}>
                                    <div className="cardDownload" style={{ background: "rgba(0,74,136,0.1)" }}>
                                      <div style={{ background: "transparent" }}>
                                        {item.documento === "" ? (
                                          <>
                                            <NavLink to="/pagina_construccion" style={{ textDecoration: "none" }}>
                                              <div>
                                                <span
                                                  style={{
                                                    background: "transparent",
                                                    color: "#111C4E",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item.titulo}
                                                </span>
                                                <br />
                                                <span
                                                  style={{
                                                    background: "transparent",
                                                    color: "#4D4D4D",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {item.text}
                                                </span>
                                                <br />
                                                <Icon
                                                  icon={Download}
                                                  style={{ background: "transparent", color: "#4D4D4D" }}
                                                />
                                              </div>
                                            </NavLink>
                                          </>
                                        ) : (
                                          <>
                                            <a
                                              href={item.documento}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ background: "transparent", textDecoration: "none" }}
                                            >
                                              <span
                                                style={{
                                                  background: "transparent",
                                                  color: "#111C4E",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {item.titulo}
                                              </span>
                                              <br />
                                              <span
                                                style={{
                                                  background: "transparent",
                                                  color: "#4D4D4D",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {item.text}
                                              </span>
                                              <br />
                                              <Icon
                                                icon={Download}
                                                style={{ background: "transparent", color: "#4D4D4D" }}
                                              />
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        </Col>
                      </>
                    ) : (
                      <>
                        {descargas.map((item) => {
                          return (
                            <Col style={{ background: "transparent", marginBottom: "10px" }}>
                              <div className="cardDownload">
                                <div style={{ background: "transparent" }}>
                                  {item.documento === "" ? (
                                    <>
                                      <NavLink to="/pagina_construccion" style={{ textDecoration: "none" }}>
                                        <div>
                                          <span
                                            style={{
                                              background: "transparent",
                                              color: "#004A88",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {item.titulo}
                                          </span>
                                          <br />
                                          <span
                                            style={{ background: "transparent", color: "#4D4D4D", fontSize: "12px" }}
                                          >
                                            {textosGral[language][2]}
                                          </span>
                                          <br />
                                          <Icon
                                            icon={Download}
                                            style={{ background: "transparent", color: "#4D4D4D" }}
                                          />
                                        </div>
                                      </NavLink>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={item.documento}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ background: "transparent", textDecoration: "none" }}
                                      >
                                        <span
                                          style={{ background: "transparent", color: "#004A88", fontWeight: "bold" }}
                                        >
                                          {item.titulo}
                                        </span>
                                        <br />
                                        <span style={{ background: "transparent", color: "#4D4D4D", fontSize: "12px" }}>
                                          {textosGral[language][2]}
                                        </span>
                                        <br />
                                        <Icon icon={Download} style={{ background: "transparent", color: "#4D4D4D" }} />
                                      </a>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </>
                    )}
                  </Row>
                </div>
              </div>
            )}
            <Footer />
          </div>
        </div>
      ) : split[2] === "planes_datos" ? (
        <Planes />
      ) : split[2] === "controles_refrigeracion_IMBERA" ? (
        <Control_refrigeracion_imbera />
      ) : null}
      {modalGif === true && (
        <div className="modalGif">
          <img
            src={require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/lumina1.gif")}
            alt="img"
          />
          <div className="closeGif" onClick={() => setModalGif(false)}>
            x
          </div>
        </div>
      )}

      <Contacto />
    </>
  );
}

export default Detalle;
