import React, { useEffect, useContext } from "react";
import LanguageContext from "context/context-language";
let textos = {
    es: [
        {
            seccion1: [                
                "Servicios de diseño: OMD",
               "1.-Sistemas Embebidos (Placa Electrónica)",
               ["Enrutador 3G/4G", "Pantallas de refrigeración", "Dispositivo de máquina expendedora", "Dispositivos IoE/IoT", "Entre muchos otros..."],
               "2.- Diseño de redes", 
               ["VPN's, redundancia, etc..."],
               "3.- Plataformas Web, desarrollo de Apps y repositorio de firmware"
            ],
            seccion2: [
                "Sistema embebido OMD",
                "Desarrollar sistemas basados sobre los requisitos del cliente.",
                "Capacidades y habilidades",
                ["Sistema operativo: Linux, FreeRTOS y/o núcleo propio", "Protocolos: IP, Modbus, DNP3, MQTT, sockets web, etc...", "Tecnologías inalámbricas: Bluetooth, BLE, LoRa, WiSUN y Redes Celulares", "Procesadores 8 a 64 bits", "Sistemas de pago EMV, NFC... entre otros", "Esquemas y diseño usando Altium trabajando con diferente paquete "]
            ]
        }       

    ],
    en: [
        {
            seccion1: [                
                "Design Services: OMD",
               "1.-Embedded Systems (Electronic Board)",
               ["3G/4G Router", "Refrigeration Displays", "Vending Machine Devices", "IoE/IoT Devices", "And many more..."],
               "2.- Network Design.", 
               ["VPNs, redundancy, and more..."],
               "3.- Web Platforms, App Development, and Firmware Repository"
            ],
            seccion2: [
                "OMD Embedded System",
                "Development of systems based on customer requirements.",
                "Capabilities and Skills",
                ["Operating Systems: Linux, FreeRTOS, and/or proprietary kernel, FreeRTOS y/o núcleo propio", "Protocols: IP, Modbus, DNP3, MQTT, web sockets, etc.", "Wireless Technologies: Bluetooth, BLE, LoRa, WiSUN, and Cellular Networks", "Processors: 8 to 64 bits", "EMV, NFC, and other payment systems", "Schematics and design using Altium and other packages"]
            ]
        }       

    ]
}
function Servicios_diseño(){
    const { language, handleLanguage } = useContext(LanguageContext);  
    let info = textos[language];
    let seccion1 = info[0].seccion1;
    let seccion2 = info[0].seccion2;

    useEffect(() => {
        
    }, []);
    return(
        <>
            <div className="card-detalle-m">
                <div style={{width: '100%'}}>
                    <video className="videoServicios" autoPlay loop controls muted><source src={require('../assets/img/productos/servicios_diseño/things.m4v')} type="video/mp4"/>Your browser does not support the video tag.</video>
                </div>
            </div>
            <div className="card-detalle card-detalle-s">
                <div className='text1'>
                    <div>
                        <div style={{background: 'transparent', fontWeight: 'bold', color: '#4D4D4D', fontSize: '20px', marginBottom: '20px'}}>{seccion1[0]}</div>                        
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion1[1]}</div>                        
                        <div>
                            {
                                seccion1[2].map(item => {
                                    return(
                                        <>
                                            <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{item}</li>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion1[3]}</div>                        
                        <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{seccion1[4][0]}</li>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion1[5]}</div>                        
                    </div>
                </div>
               
                <div className='img1 im1-s'>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundImage: "url(" + require('../assets/img/productos/servicios_diseño/servicios_diseño.jpg') + ")",
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',                                            
                            position: 'relative',
                            width: '100%',
                            marginLeft: '10px',
                            WebkitBorderRadius: '10px',  
                            marginTop: '0',                                            
                        }}
                    ></div>
                </div>
            </div>
            <div className="card-detalle card-detalle-s">

                <div className='img1' style={{marginLeft: '0', paddingRight: '20px'}}>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundImage: "url(" + require('../assets/img/productos/servicios_diseño/sistema_embebido.jpg') + ")",
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',                                            
                            position: 'relative',
                            width: '100%',
                            marginLeft: '10px',
                            WebkitBorderRadius: '10px',  
                            marginTop: '0',                                            
                        }}
                    ></div>
                </div>
                <div className='text1'>
                    <div>
                        <div style={{background: 'transparent', fontWeight: 'bold', color: '#4D4D4D', fontSize: '20px', marginBottom: '20px'}}>{seccion2[0]}</div>                        
                        <div style={{background: 'transparent', color: '#4D4D4D', fontSize: '15px', marginBottom: '20px'}}>{seccion2[1]}</div>                        
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion2[2]}</div>                        
                        <div>
                            {
                                seccion2[3].map(item => {
                                    return(
                                        <>
                                            <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{item}</li>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion2[4]}</div>                        
                        
                    </div>
                </div>
                <div className='im1-s' style={{marginLeft: '0', paddingRight: '20px'}}>
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundImage: "url(" + require('../assets/img/productos/servicios_diseño/sistema_embebido.jpg') + ")",
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',                                            
                            position: 'relative',
                            width: '100%',
                            marginLeft: '10px',
                            WebkitBorderRadius: '10px',  
                            marginTop: '0',                                            
                        }}
                    ></div>
                </div>
               
            </div>
        </>
    )
}

export default Servicios_diseño;