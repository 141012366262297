import React, { Component, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../assets/css/about.css";
import Footer from "../components/Footer/Footer1";
import LanguageContext from "context/context-language";

export default function AcercaDe() {
  const { language, handleLanguage } = useContext(LanguageContext);
  let contenido = {
    es: [
      " Más de 20 años de experiencia nos respaldan",
      "Desde hace 25 años estamos dedicados  al diseño, manufactura e integración de sistemas de telecomunicación y soluciones basadas en redes inalámbricas y sistemas embebidos para productos y servicios IoT (M2M/IoE).",
      "es reconocida como una de las mejores empresas de ingeniería en desarrollo de hardware y software de México, por lo que opera como casa de diseño las principales marcas de semiconductores.",
      "Nuestra tecnología nos permite brindar servicios IoT en México y otros países. Contamos con presencia en 60 ciudades de la República Mexicana*.",
      "Políticas",
      "Ver más",
    ],
    en: [
      "Over 20 years of experience back us up",
      "For the past 25 years, we have been dedicated to the design, manufacturing, and integration of telecommunications systems and solutions based on wireless networks and embedded systems for IoT (M2M/IoE) products and services.",
      "is recognized as one of the top engineering companies in hardware and software development in Mexico, serving as a design house for leading semiconductor brands.",
      "Our technology allows us to provide IoT services in Mexico and other countries. We have a presence in 60 cities throughout the Mexican Republic*.",
      "Políticas",
      "See more",
    ],
  };
  useEffect(() => {
    window.document.title = "SOFTEL";
    const element = document.getElementById("inicio");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, []);

  return (
    <>
      <div id="inicio"></div>
      <div className="headerAbout">
        <div className="divAvisoTituloA">{contenido[language][0]}</div>
      </div>
      <div className="bodyAvisoA" style={{ marginBottom: "50px" }}>
        <Row md="2" sm="1" xs="1">
          <Col style={{ fontSize: "20px", fontWeight: "bold", paddingTop: "40px" }}>
            <p>
              <div style={{ color: "#004A88 " }}>Misión: </div> Recolectamos, transportamos, procesamos y cuidamos los
              datos críticos para entregar información de valor al negocio de nuestros clientes, mediante el desarrollo
              de dispositivos electrónicos y herramientas digitales con tecnología de punta, calidad, sustentabilidad y
              cumplimiento normativo. <br />
              <br />
              <br />
              <div style={{ color: "#004A88 " }}>Visión: </div>
              Establecernos como la empresa mexicana líder en desarrollo tecnológico en transformación digital, con
              énfasis en el Internet de las cosas (IoT) e inteligencia artificial para la obtención, manejo y
              explotación de datos estratégicos, mediante plataformas, dispositivos electrónicos y soluciones a la
              medida para incrementar el negocio de nuestros clientes y socios comerciales a nivel mundial.
              <br />
              <br />
              <div style={{ color: "#004A88 " }}>Valores: </div>
              ●Adaptabilidad
              <br /> ● Confiabilidad
              <br /> ● Integridad
              <br /> ● Excelencia
            </p>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="containerClassCertificaciones" style={{ marginTop: "10px" }}>
              <div style={{ color: "#63656A", fontWeight: "bold", marginBottom: "20px" }}>{contenido[language][4]}</div>
              <a
                style={{ textDecoration: "none" }}
                href={require("../assets/docs/Título Easycool CL 9 M2713014 11 junio 2024.pdf")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="classCertificaciones">
                  <div
                    style={{
                      position: "relative",
                      width: "70%",
                      color: "#63656A",
                      fontSize: "15px",
                      fontWeight: "bold",
                      textAlign: "justify",
                      paddingLeft: "10px",
                    }}
                  >
                    La marca "EASYCOOL®" está registrada y protegida por las leyes de propiedad intelectual vigentes. <br/> El uso no autorizado de la marca o el nombre de la empresa puede constituir una infracción.
                  </div>
                  <div className="textM">
                    <div>{contenido[language][5]}</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="img" />
                    </div>
                  </div>
                </div>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href={require("../assets/docs/Política de Seguridad de la Información SOFTEL.pdf")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="classCertificaciones">
                  <div
                    style={{
                      position: "relative",
                      width: "70%",
                      color: "#63656A",
                      fontSize: "15px",
                      fontWeight: "bold",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    Política de Seguridad de la información
                  </div>
                  <div className="textM">
                    <div>{contenido[language][5]}</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="img" />
                    </div>
                  </div>
                </div>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href={require("../assets/docs/Política de Calidad SOFTEL.jpeg")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="classCertificaciones">
                  <div
                    style={{
                      position: "relative",
                      width: "70%",
                      color: "#63656A",
                      fontSize: "15px",
                      fontWeight: "bold",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    Política de Calidad
                  </div>
                  <div className="textM">
                    <div>{contenido[language][5]}</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="img" />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
