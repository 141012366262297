import React, { useCallback, useState, useContext }  from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particles from "../variables/particles";
import "../assets/css/pagina_construccion.css";
import Contacto from "../components/contacto/contacto";
import ContactoContext from "../context/context-contacto";

const Pagina_Construccion = () => {
    const particlesInit = useCallback(async engine => {        
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    const [ viewContacto, setViewContacto ] = useState(false);
    const { openModal, HandleContacto } = useContext(ContactoContext);
    return (
        <>
        <Particles id="tsparticles"  init={particlesInit} loaded={particlesLoaded} options={particles}/>
        <div className="container-pagina-construccion">
            <div style={{background: 'rgba(0,0,0,0.3)', position: 'relative', width: '500px', textAlign: 'center', borderRadius: '10px'}}>
                <div style={{fontWeight: 'bold', background: 'transparent', fontSize: '40px', color : '#fff', marginBottom: '10px'}}>En construcción</div>
                <div style={{fontWeight: 'normal', background: 'transparent', fontSize: '30px', color : '#fff', marginBottom: '10px'}}>Para mayor información</div>
                <div className="arrowCont"></div>
                <div className="btn-contact-c" style={{left: 'calc(50% - 50px)', marginBottom: '20px'}} onClick={() => HandleContacto(true)}>
                    Contáctanos
                </div>
            </div>

               
        </div>
        {
            openModal &&
            <Contacto/>  

        }
        

        </>
        
    )
};
export default Pagina_Construccion;