import React, { useEffect } from 'react';
import {Row, Col} from 'reactstrap';
import { NavLink } from "react-router-dom";
import Titulo_autorizacion from "../assets/docs/TÍTULO DE AUTORIZACIÓN A SOFTEL COMERCIALIZADORA 24 SEP 2015.pdf";
import Footer from '../components/Footer/Footer1';

const MVNO = () => {
  useEffect(() => {
    const element = document.getElementById("inicio");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });  
  }, []);
  return (
    <>
      <div id='inicio'></div>
      <div className='headerAboutIFT' id='inicio' style={{top: '70px'}}>
          <div className='divAvisoTitulo'>
          
          </div>
      </div>
      <div className='bodyAviso' style={{marginBottom: '50px'}}>
          <p style={{'display': 'flex', 'fontSize': '14px'}}>
            <div style={{'position': 'relative','top': '10px', 'marginRight': '10px','width': '30px','borderTop': 'solid 1px #5F6A6A'}}></div>Decreto Presidencial<br/>            
          </p>
          <p style={{'color': '#00577d', 'fontWeight': 'bold'}}>
            11 de Junio del 2013 <br/>
            Manifiesta en el Inciso II del Artículo 6°
          </p>
          <Row>
            <Col>
              <div>
                <p>
                  “II. Las telecomunicaciones son servicios públicos de interés general, 
                  por lo que el Estado garantizará que sean prestados en condiciones de competencia, 
                  calidad, pluralidad, cobertura universal, interconexión, convergencia, continuidad,
                  acceso libre y sin injerencias arbitrarias.”
                </p>
                <p>
                  El 26 de octubre de 2015, por parte del IFETEL, SOFTEL es “autorizado para establecer y 
                  operar o explotar una comercializadora de servicios de telecomunicaciones sin que adquiera el carácter de concesionaria”.
                </p>
              </div>
           
                <p>
                  Esta autorización, que es por 10 años, le permite, según el artículo 170 de la Ley Federal de Telecomunicaciones y Radiodifusión:
                </p>
                <p>
                  “Proporcionar servicios propios y acceder a servicios mayoristas, para revender 
                  servicios públicos de telecomunicaciones en el país a usuarios finales, mediante el uso 
                  de la capacidad previamente adquirida de una o varias redes públicas de telecomunicaciones de un Concesionario.”
                </p>              
            </Col>
          </Row>
          <div style={{'display': 'flex', 'justifyContent': 'flex-end'}}>
            <NavLink to={'/IFTD'} style={{textDecoration: 'none'}}><div className='btnIFT'>Más Información</div><br/></NavLink>
            {/* <a href='http://rpc.ift.org.mx/rpc/pdfs/261015AUTORIZACION85867.pdf' target='_blank' rel="noopener noreferrer"><div className='btnIFT'>Ver autorización </div></a> */}
            <a href={Titulo_autorizacion} target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none'}}><div className='btnIFT'>Ver autorización</div></a>
            
          </div>
      </div>
      <Footer/>
      </>
  );
};

export default MVNO;