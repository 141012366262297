const optionMenu = {
  es: [
    [
      "Servicios IoT",
      [
        ["Servicios"],
        ["Red Mesh para tus dispositivos IoT", "/servicios/red_mesh_para_tus_dispositivos_IoT"],
        [
          "Medidores IoT WASION - SOFTEL",
          "/servicios/medidores_iot_wasion_softel",
          require("../assets/img/Iconos Navegación/WasionSoftel.png"),
        ],
        [
          "Comunicación Mesh y Celular para Medidores Eléctricos",
          "/servicios/comunicacion_mesh_y_celular_para_medidores_electricos",
          require("../assets/img/Iconos Navegación/ComunicacionMedidores.png"),
        ],
        [
          "Comunicación Mesh y Celular para Luminarias Públicas",
          "/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas",
          require("../assets/img/Iconos Navegación/ComunicacionLuminarias.png"),
        ],
        [
          "Monitoreo de Productos Refrigerados",
          "/servicios/monitoreo_de_productos_refrigerados",
          require("../assets/img/Iconos Navegación/ProductosRefrigerados.png"),
        ],
        [
          "Monitoreo de Activos Indoor",
          "/servicios/monitoreo_de_activos_indoor",
          require("../assets/img/Iconos Navegación/ActivosIndoor.png"),
        ],
        [
          "Enlaces monitoreados de Comunicación Vía Celular",
          "/servicios/enlaces_monitoreados_de_comunicacion_via_celular",
          require("../assets/img/Iconos Navegación/ComunicacionCelular.png"),
        ],
        [
          "Monitoreo de Entorno ATMs",
          "/servicios/monitore_entornos_atms",
          require("../assets/img/Iconos Navegación/EntornoAtms.png"),
        ],
        [
          "SIMs y Planes de Datos",
          "/servicios/planes_datos",
          require("../assets/img/Iconos Navegación/SimsPlanesDatos.png"),
        ],
      ],
    ],
    [
      "Productos IoT",
      [
        ["Productos"],
        [""],
        [
          "Control Refrigeración EASYCOOL® ",
          //"/productos/control_refrigeracion_easy_cool",
          "/contacto",
          require("../assets/img/Iconos Navegación/EasyCool.png"),
        ],
        [
          "Controles de refrigeración IMBERA",
          "/productos/controles_refrigeracion_IMBERA",
          require("../assets/img/Iconos Navegación/RefrigeracionIMBERA.png"),
        ],
        [
          "Servicios de Diseño",
          "/productos/servicios_diseño",
          require("../assets/img/Iconos Navegación/ServiciosDiseño.png"),
        ],
      ],
    ],
    ["Contacto"],
    [
      "Nosotros",
      [
        ["Nosotros"],
        [""],
        ["Certificaciones", "/nosotros/certificaciones", require("../assets/img/Certificaciones.png")],
        ["Políticas", "/nosotros/politicas", require("../assets/img/Politicas.png")],
      ],
    ],
    ["ES", ["Cambiar idioma", "Español - ES", "Ingles - EN"]],
  ],
  en: [
    [
      "IoT Services",
      [
        ["Services"],
        ["Mesh network for your IoT devices", "/servicios/red_mesh_para_tus_dispositivos_IoT"],
        [
          "WASION-SOFTEL IoT Meters",
          "/servicios/medidores_iot_wasion_softel",
          require("../assets/img/Iconos Navegación/WasionSoftel.png"),
        ],
        [
          "Mesh and Cellular Communication for Electric Meters",
          "/servicios/comunicacion_mesh_y_celular_para_medidores_electricos",
          require("../assets/img/Iconos Navegación/ComunicacionMedidores.png"),
        ],
        [
          "Mesh and Cellular Communication for Public Lighting",
          "/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas",
          require("../assets/img/Iconos Navegación/ComunicacionLuminarias.png"),
        ],
        [
          "Monitoring of Refrigerated Products",
          "/servicios/monitoreo_de_productos_refrigerados",
          require("../assets/img/Iconos Navegación/ProductosRefrigerados.png"),
        ],
        [
          "Indoor Asset Monitoring",
          "/servicios/monitoreo_de_activos_indoor",
          require("../assets/img/Iconos Navegación/ActivosIndoor.png"),
        ],
        [
          "Monitored Cellular Communication Connections",
          "/servicios/enlaces_monitoreados_de_comunicacion_via_celular",
          require("../assets/img/Iconos Navegación/ComunicacionCelular.png"),
        ],
        [
          "ATM Environment Monitoring",
          "/servicios/monitore_entornos_atms",
          require("../assets/img/Iconos Navegación/EntornoAtms.png"),
        ],
        [
          "SIM Cards and Data plans",
          "/servicios/planes_datos",
          require("../assets/img/Iconos Navegación/SimsPlanesDatos.png"),
        ],
      ],
    ],
    [
      "IoT Products",
      [
        ["Products"],
        [""],
        [
          "EASYCOOL®  Refrigeration Control",
          //"/productos/control_refrigeracion_easy_cool",
          "/contacto",
          require("../assets/img/Iconos Navegación/EasyCool.png"),
        ],
        [
          "IMBERA Refrigeration Controls",
          "/productos/controles_refrigeracion_IMBERA",
          require("../assets/img/Iconos Navegación/RefrigeracionIMBERA.png"),
        ],
        [
          "Design Services",
          "/productos/servicios_diseño",
          require("../assets/img/Iconos Navegación/ServiciosDiseño.png"),
        ],
      ],
    ],
    ["Contact"],
    [
      "Us",
      [
        ["Us"],
        [""],
        ["Certifications", "nosotros/certificaciones", require("../assets/img/Certificaciones.png")],
        ["Policies", "nosotros/politicas", require("../assets/img/Politicas.png")],
      ],
    ],
    ["ES", ["Change language", "Spanish - ES", "English - EN"]],
  ],
};

export default optionMenu;
