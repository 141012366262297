import React, { useEffect, useState, useContext } from "react";
import "assets/css/panelHeaderResponsve.css";
import optionMenu from "variables/menu";
import { Icon } from "@iconify/react";
import iconDown from "@iconify/icons-ic/baseline-arrow-drop-down";
import iconUp from "@iconify/icons-ic/baseline-arrow-drop-up";
import world from "@iconify/icons-clarity/world-outline-badged";
import { NavLink } from "react-router-dom";
import LanguageContext from "context/context-language";
import { useLocation } from "react-router-dom";

function panelHeaderResponsive({ isOpen, active }) {
  //const [ isOpen, setIsOpen ] = useState(false);
  const [option, setOption] = useState(null);
  const { language, handleLanguage } = useContext(LanguageContext);
  let menu = optionMenu[language];

  const { pathname } = useLocation();
  let split = pathname.split("/")[2];

  useEffect(() => {
    console.log(split);
  }, [split]);

  const changeOption = (id) => {
    if (id === option) {
      setOption(null);
    } else {
      setOption(id);
    }
  };

  return (
    <>
      <div className="panelHeaderResponsive">
        <div className="panelHeaderResponsive-options">
          <ul>
            <li onClick={() => isOpen()}>
              <NavLink to={"/"} style={{ color: split === undefined ? "#004A88" : "" }}>
                Home
              </NavLink>
            </li>
            <li onClick={() => changeOption(0)}>
              <div>
                {menu[0][0]}
                <Icon icon={option === 0 ? iconUp : iconDown} />
              </div>

              {option === 0 && (
                <>
                  <div className="submenu">
                    {menu[0][1].map((item, index) => {
                      let opt = item[1];
                      let splitI = undefined;
                      if (opt) {
                        splitI = opt.split("/")[2];
                      }
                      if (index > 0) {
                        return (
                          <>
                            <NavLink to={item[1]} onClick={() => isOpen()}>
                              <div className="submenuaa">
                                <div> {index > 1 && <img src={item[2]} alt="img" />}</div>
                                <div style={{ color: split === splitI ? "#004A88" : "" }}>{item[0]}</div>
                                {index === 1 && <div className="arrow-menu" style={{ top: "10px", left: "5px" }}></div>}
                              </div>
                            </NavLink>
                          </>
                        );
                      }
                    })}
                  </div>
                </>
              )}
            </li>
            <li onClick={() => changeOption(1)}>
              <div>
                {menu[1][0]} <Icon icon={option === 1 ? iconUp : iconDown} />
              </div>
              {option === 1 && (
                <>
                  <div className="submenu">
                    {menu[1][1].map((item, index) => {
                      if (item[1] !== undefined && index > 0) {
                        let opt = item[1];
                        let splitI = undefined;
                        if (opt) {
                          splitI = opt.split("/")[2];
                        }
                        return (
                          <>
                            <NavLink to={item[1]} onClick={() => isOpen()}>
                              <div className="submenuaa">
                                <div> {index > 1 && <img src={item[2]} alt="img" />}</div>
                                <div style={{ color: split === splitI ? "#004A88" : "" }}>{item[0]}</div>
                              </div>
                            </NavLink>
                          </>
                        );
                      }
                    })}
                  </div>
                </>
              )}
            </li>
            <li onClick={() => isOpen()}>
              <NavLink to={"/contacto"}>EASYCOOL®</NavLink>
            </li>
            <li onClick={() => isOpen()}>
              <NavLink to={"/contactanos"}>{menu[2][0]}</NavLink>
            </li>
            {/* <li onClick={() => isOpen()}><NavLink to={'/acerca_de'}>{menu[3][0]}</NavLink></li>                         */}
            <li onClick={() => changeOption(3)}>
              <div>
                {menu[3][0]} <Icon icon={option === 1 ? iconUp : iconDown} />
              </div>
              {option === 3 && (
                <>
                  <div className="submenu">
                    {menu[3][1].map((item, index) => {
                      if (item[1] !== undefined && index > 0) {
                        let opt = item[1];
                        let splitI = undefined;
                        if (opt) {
                          splitI = opt.split("/")[2];
                        }
                        return (
                          <>
                            <NavLink to={item[1]} onClick={() => isOpen()}>
                              <div className="submenuaa">
                                <div> {index > 1 && <img src={item[2]} alt="img" />}</div>
                                <div style={{ color: split === splitI ? "#004A88" : "" }}>{item[0]}</div>
                              </div>
                            </NavLink>
                          </>
                        );
                      }
                    })}
                  </div>
                </>
              )}
            </li>
            <li onClick={() => changeOption(2)}>
              <div>
                <Icon icon={world} style={{ marginRight: "5px" }} />
                {language.toUpperCase()}
                <Icon icon={option === 2 ? iconUp : iconDown} />
              </div>
              {option === 2 && (
                <div className="submenu">
                  <div style={{ fontSize: "12px", marginRight: "10px" }}>
                    <input
                      type="radio"
                      value={"es"}
                      name="gender"
                      checked={language === "es"}
                      onChange={() => {
                        handleLanguage("es");
                        isOpen(false);
                      }}
                    />{" "}
                    {menu[4][1][1]}
                  </div>
                  <div style={{ fontSize: "12px", marginRight: "5px" }}>
                    <input
                      type="radio"
                      value={"en"}
                      name="gender"
                      checked={language === "en"}
                      onChange={() => {
                        handleLanguage("en");
                        isOpen(false);
                      }}
                    />{" "}
                    {menu[4][1][2]}
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default panelHeaderResponsive;
