import React, { useContext } from "react";
import LanguageContext from "context/context-language";
import "assets/css/medidores.css";

let textos = {
    es: [
        {
            seccion1: [
                "Control para refrigeradores y congeladores",
                ["Permite monitorear el ciclo de enfriamiento y ofrecer a tu cliente una app para manipular configuraciones que cubren las diferentes necesidades o tipos de enfriadores."],
                "Ofrecemos",
                ["Fácil instalación", "Conocimiento y manipulación del estado actual de temperatura", "Modos de operación para ahorrar energía", "Validación de alarmas operativas", "Apps técnicas y mantenimiento disponibles", "Compatibilidad con modelos actuales, ideal como refacción"],
            ]
        }       

    ],
    en: [
        {
            seccion1: [
                "Control for refrigerators and freezers",
                ["Allows monitoring of the cooling cycle and provides your customer with an app to manipulate settings that cover different cooling needs or types."],
                "We offer",
                ["Easy installation", "Knowledge and manipulation of the current temperature status", "Operating modes for energy saving", "Validation of operational alarms", "Technical apps and maintenance available", "Compatibility with current models, ideal as a spare part"],
            ]
        }       

    ]
}
function Control(){
    const { language, handleLanguage } = useContext(LanguageContext);  
    let info = textos[language];
    let seccion1 = info[0].seccion1;    
    
    return(
        <>
            <div className="card-detalle card-detalle-med">
                <div className='text1'>
                    <div>
                        <div style={{background: 'transparent', fontWeight: 'bold', color: '#4D4D4D', fontSize: '20px', marginBottom: '20px'}}>{seccion1[0]}</div>
                        <div style={{background: 'transparent', color: '#63656A', marginBottom: '10px', fontSize: '15px'}}>
                            {seccion1[1][0]}<br/><br/>
                            {seccion1[1][1]}<br/><br/>
                            {seccion1[1][2]}
                        </div>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginTop: '20px' , marginBottom: '10px', fontSize: '15px'}}>{seccion1[2]}</div>
                        <div>
                            {
                                seccion1[3].map(item => {
                                    return(
                                        <>
                                            <li style={{fontSize: '15px', color:'#63656A', listStyle: 'square', borderTop: 'solid 1px #63656A', padding: '8px 0 8px 0'}}>{item}</li>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div style={{fontWeight: 'bold', color: '#004A88', fontSize: '20px', marginBottom: '10px', fontSize: '17px', textDecoration: 'underline'}}>{seccion1[4]}</div>
                    </div>
                </div>
               
                <div className='img1 im1-s'>
                    <div
                        style={{
                            alignItems: 'center',
                            /* backgroundImage: "url(" + require('../assets/img/productos/control_refrigeracion_easy_cool/EasyCool_Vertical.png') + ")", */
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'center',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',                                            
                            position: 'relative',
                            width: '100%',
                            marginLeft: '10px',
                            WebkitBorderRadius: '10px',  
                            marginTop: '0',                                            
                        }}
                        className="im-c"
                    ></div>
                </div>
            </div>            
        </>
    )
}

export default Control;