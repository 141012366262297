import React, { Component, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import "../assets/css/about.css";
import Footer from "components/Footer/Footer1";
import LanguageContext from "context/context-language";

export default function AcercaDe() {
  const { language, handleLanguage } = useContext(LanguageContext);
  let contenido = {
    es: [
      " Más de 20 años de experiencia nos respaldan",
      "Desde hace 25 años estamos dedicados  al diseño, manufactura e integración de sistemas de telecomunicación y soluciones basadas en redes inalámbricas y sistemas embebidos para productos y servicios IoT (M2M/IoE).",
      "es reconocida como una de las mejores empresas de ingeniería en desarrollo de hardware y software de México, por lo que opera como casa de diseño las principales marcas de semiconductores.",
      "Nuestra tecnología nos permite brindar servicios IoT en México y otros países. Contamos con presencia en 60 ciudades de la República Mexicana*.",
      "Certificaciones",
      "Ver más",
    ],
    en: [
      "Over 20 years of experience back us up",
      "For the past 25 years, we have been dedicated to the design, manufacturing, and integration of telecommunications systems and solutions based on wireless networks and embedded systems for IoT (M2M/IoE) products and services.",
      "is recognized as one of the top engineering companies in hardware and software development in Mexico, serving as a design house for leading semiconductor brands.",
      "Our technology allows us to provide IoT services in Mexico and other countries. We have a presence in 60 cities throughout the Mexican Republic*.",
      "Certifications",
      "See more",
    ],
  };
  useEffect(() => {
    window.document.title = "SOFTEL";
    const element = document.getElementById("inicio");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, []);

  return (
    <>
      <div id="inicio"></div>
      <div className="headerAbout">
        <div className="divAvisoTituloA">{contenido[language][0]}</div>
      </div>
      <div className="bodyAvisoA" style={{ marginBottom: "50px" }}>
        <Row md="2" sm="1" xs="1">
          <Col style={{ fontSize: "20px", fontWeight: "bold", paddingTop: "40px" }}>
            <p>{contenido[language][1]}</p>
            <p>
              <span className="spanNegrita">SOFTEL </span> {contenido[language][2]} <br />
              <br />
              {contenido[language][3]}
            </p>
            <p style={{ fontSize: "12px" }}>
              *Acapulco, Aguascalientes, Campeche, Cancún, Cd. del Carmen, Cd. Juárez, Cd. Obregón, Cd. Victoria,
              Celaya, Chetumal, Chihuahua, Coatzacoalcos, Colima, Córdoba, Cozumel, Cuernavaca, Ciudad de México,
              Durango, Guadalajara, Hermosillo, Irapuato, Jalapa, La Paz, León, Los Cabos, Los Mochis, Manzanillo,
              Matamoros, Mazatlán, Mexicali, Monclova, Monterrey, Morelia, Mérida, Nuevo Laredo, Oaxaca, Pachuca,
              Piedras Negras, Poza Rica, Puebla, Querétaro, Reynosa, Salina Cruz, Saltillo, San Luis, Tampico,
              Tapachula, Tepic, Tijuana, Tlaxcala, Toluca, Torreón, Tuxtla Gtz., Veracruz, Villahermosa, Zacatecas,
              Zamora y Zihuatanejo.
            </p>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end", paddingTop: "40px" }}>
            <div className="containerClassCertificaciones">
              <div style={{ color: "#63656A", fontWeight: "bold", marginBottom: "20px" }}>{contenido[language][4]}</div>
              <NavLink to="/IFT" style={{ textDecoration: "none" }}>
                <div className="classCertificaciones">
                  <div className="imgM">
                    <img src={require("../assets/img/image 7.png")} alt="img" />
                  </div>
                  <div className="textM">
                    <div>{contenido[language][5]}</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="img" />
                    </div>
                  </div>
                </div>
              </NavLink>

              <a
                href={require("../assets/docs/Documentos de certificación SOF-2522 SGSI.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="classCertificaciones">
                  <div className="imgM">
                    <div className="certificaciones">
                      <div>
                        <img src={require("../assets/img/Logo_ISO_27001_2015_A.png")} alt="" />
                      </div>
                      <div className="textIso">
                        <span style={{ fontWeight: "bold", fontSize: "13px", color: "#004A88" }}>ISO 27001:2022</span>
                      </div>
                    </div>
                  </div>
                  <div className="textM">
                    <div> Ver más</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="" />
                    </div>
                  </div>
                </div>
              </a>
              <a
                href={require("../assets/docs/Documentos de certificación SOF-2522 SGC.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="classCertificaciones">
                  <div className="imgM">
                    <div className="certificaciones">
                      <div>
                        <img src={require("../assets/img/Logo_ISO_9001_2015_A.png")} alt="" />
                      </div>
                      <div className="textIso">
                        <span style={{ fontWeight: "bold", fontSize: "13px", color: "#004A88" }}>ISO 9001:2015</span>
                      </div>
                    </div>
                  </div>
                  <div className="textM">
                    <div> Ver más</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="" />
                    </div>
                  </div>
                </div>
              </a>
              <a
                href={require("../assets/docs/2020-07-27 Comunicado UL con certificados.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="classCertificaciones">
                  <div className="imgM">
                    <img src={require("../assets/img/image 8.png")} alt="img" />
                  </div>
                  <div className="textM">
                    <div>{contenido[language][5]}</div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="" />
                    </div>
                  </div>
                </div>
              </a>
              <a
                href={require("../assets/docs/Case-0305_Wi-SUN-Certificate-FAN-Profile.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="classCertificaciones">
                  <div className="imgM">
                    <img src={require("../assets/img/wisun.png")} alt="" />
                  </div>
                  <div className="textM">
                    <div> NICs </div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="" />
                    </div>
                  </div>
                </div>
              </a>
              <a
                href={require("../assets/docs/Case-0318_Wi-SUN-Certificate-FAN-Profile.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className="classCertificaciones">
                  <div className="imgM">
                    <img src={require("../assets/img/wisun.png")} alt="" />
                  </div>
                  <div className="textM">
                    <div> Border Routers </div>
                    <div className="arrowA">
                      <img src={require("../assets/img/arrow-down.png")} style={{ marginLeft: "5px" }} alt="" />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
