import { Col, Row } from "reactstrap";

const detalle = {
  servicios: {
    red_mesh_para_tus_dispositivos_IoT: {
      lateral: {
        icono: require("../assets/img/servicios/servicios_iot/ServiciosIoT.png"),
        titulo: "Mesh Network for Your IoT Devices",
        img: [
          require("../assets/img/servicios/servicios_iot/WiSun-Alliance-logo.png"),
          require("../assets/img/servicios/servicios_iot/lte-m_logo_blanco.png"),
          require("../assets/img/servicios/servicios_iot/narrowband_logo_blanco.png"),
        ],
        descrgas: false,
      },
      contenido: {
        descripcion:
          "Our public telecommunications network enables IoT-related applications that support and drive the digital transformation of society.",
        secciones: [
          {
            titulo: "Advantages of the Solution:",
            subtitulo2: [
              "We have proprietary technology certified by the ",
              "NICs (Network Interface Cards) ",
              "and ",
              " Border Routers. ",
              "We provide every element of the Smart Grid.",
            ],
            icon: require("../assets/img/servicios/servicios_iot/ventajas.png"),
            points: [
              "Network Interface Cards (NICs) for different markets with an excellent cost-benefit ratio",
              "Manufacturing our own border routers reduces network costs",
              "Analytical capacity of our NOC (Network Operations Center) enables real-time monitoring of network quality of service (QoS) through Network Management and Monitoring Platforms",
              "High availability (Always on)",
            ],
            descripcion:
              "Network equipment with high availability thanks to redundant design and backup batteries in case of power failure.",
            imagen: require("../assets/img/servicios/servicios_iot/ventajas.jpg"),
            left: true,
          },
          {
            titulo: "Features",
            subtitulo: "Our Mesh network excels in the following areas:",
            icon: require("../assets/img/servicios/servicios_iot/NET.png"),
            points: [
              "Full interoperability",
              "Reduced implementation times in newly covered areas",
              "High-speed transmission without losses",
              "High availability (Always on)",
              "Protection against interference",
              "High security",
            ],
            imagen: require("../assets/img/servicios/servicios_iot/caracteristicas.jpg"),
            left: false,
          },
          {
            titulo: "NOC",
            subtitulo:
              "SOFTEL Net is supported by our own Network Operations Center (NOC), which operates 24/7, 365 days a year, and field engineering teams to provide carrier-class service with Service Level Agreements (SLAs) that guarantee high availability and continuity for mission-critical applications.",
            icon: require("../assets/img/servicios/servicios_iot/NOC.png"),
            points: [
              "Remote diagnostics and fault resolution at various levels",
              "Alarm management and data handling using Machine Learning and Artificial Intelligence",
              "Field personnel throughout Mexico and warehouses with spare parts and tools in the country's major 58 cities",
              "In case of network disruptions, coordinated on-site visits with field personnel with established SLAs",
            ],
            imagen: require("../assets/img/servicios/servicios_iot/noc.jpg"),
            left: true,
          },
          {
            titulo: "Business Approach:",
            subtitulo:
              "We serve markets that benefit from remote data collection and exploitation generated by different types of equipment, devices, and things to make informed decisions for their business.",
            icon: require("../assets/img/servicios/servicios_iot/enfoque_negocios.png"),
            points: ["Electric (Power & Utilities)", "Refrigeration or Cold Chain", "Public lighting or Luminaires"],
            imagen: require("../assets/img/servicios/servicios_iot/enfoque.jpg"),
            left: false,
          },
        ],
      },
    },
    medidores_iot_wasion_softel: {
      lateral: {
        icono: require("../assets/img/servicios/medidores_iot_wasion_softel/WasionSoftel.png"),
        titulo: "WASION-SOFTEL IoT Meters",
        img: [],
        descrgas: false,
      },
    },
    comunicacion_mesh_y_celular_para_medidores_electricos: {
      lateral: {
        icono: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/ComunicacionMedidores.png"),
        titulo: "Mesh and Cellular Communication for Electric Meters",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/connect_vid.mp4"),
        descripcion: "",
        secciones: [
          {
            titulo: "SOFTEL P&U",
            subtitulo:
              "Our SOFTEL P&U IoT solutions enable communication for electric meters (socket, cabinet, and industrial), allowing for consumption measurements and remote user disconnection and reconnection. Our solutions comply with the GWH-0079 standard for energy meter scaling cards.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P_U.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P&U.png"),
            left: true,
          },
          {
            titulo: "SOFTEL IoT P&U Meter NIC",
            subtitulo:
              "The Scaling Card is an electronic module used to communicate with residential meters (socket) through our SOFTEL Net network, delivering data to SINAMED CFE or any other control and billing platform. It operates under current standards for electric meter communication.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/IoT_Meter_NIC.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P&U_Meter_NIC.png"),
            left: false,
          },
          {
            titulo: "SOFTEL IoT P&U Cabinet Gateway ",
            subtitulo:
              "This device communicates with cabinet-type meters through our SOFTEL Net network, delivering data to SINAMED CFE or any other control and billing platform. A single communication device can transmit information from 12 to 24 meters, making it a cost-effective option for residential complexes.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/Iot_Meter_Cabinet.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/industrial.png"),
            left: true,
          },
          {
            titulo: "SOFTEL IoT P&U Industrial NIC",
            subtitulo:
              "Our product for industrial meters enables the retrieval of complete load profiles, which are sent to SINAMED CFE or any other control and billing platform. Invoice, control, and analyze your customers' consumption with detailed data using the most reliable network and the best speed.",
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/IoT_Industrial_NIC.jpg"),
            points: [],
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/P&U_Meter_NIC.png"),
            left: false,
          },
        ],
      },
      descargas: [
        {
          titulo: "Tarjeta de Escalamiento 214",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-ESC214-ESP.pdf"),
        },
        {
          titulo: "Tarjeta de Escalamiento 229",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-ESC229-ESP.pdf"),
        },
        {
          titulo: "Tarjeta Escalamiento IoT para Medidores Eléctricos",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-GAB-ESP.pdf"),
        },
        {
          titulo: "Tarjeta Escalamiento CAT-M / NarrowBand",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_medidores_electricos/CNCT-CATM-ESP.pdf"),
        },
      ],
    },
    comunicacion_mesh_y_celular_para_luminarioas_publicas: {
      lateral: {
        icono: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/ComunicacionLuminarias.png"),
        titulo: "Mesh and Cellular Communication for Public Lighting",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/lumina_vid.mp4"),
        descripcion: "",
        secciones: [
          {
            titulo: "LIGHTING CONTROL",
            subtitulo:
              "We provide public lighting management solutions for municipalities, districts, residential complexes, and industrial parks. Our solutions contribute to optimizing energy consumption and enable timely fault detection.",
            points: [],
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/control.jpg"),
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/control_luminarias.png"),
            gif: true,
            left: true,
          },
          {
            titulo: "SOFTEL IoT NEMA 3",
            subtitulo:
              "This solution offers two-way control for turning on and off luminaires, including scheduled programming and individual manual control. It also provides alarm detection for monitoring functionality parameters.",
            points: [],
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema_3_M2.png"),
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema.png"),
            left: false,
          },
          {
            titulo: "SOFTEL IoT NEMA 7",
            subtitulo:
              "With detailed telemetry capabilities, this solution collects information on the operating status of luminaires. It allows for expanded monitoring and control, including adjusting the brightness intensity for better consumption control and energy savings.",
            points: [],
            imagen: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema_7_M.png"),
            icon: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/Nema.png"),
            left: true,
          },
        ],
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/servicios/comunicacion_mesh_y_celular_para_luminarioas_publicas/LUM-ESP.pdf"),
        },
      ],
    },
    monitoreo_de_productos_refrigerados: {
      lateral: {
        icono: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/ProductosRefrigerados.png"),
        titulo: "Monitoring of Refrigerated Products",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/protect.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Medication Refrigeration",
            subtitulo:
              "We assist in the preservation of vaccines and temperature-sensitive medication through a system that includes sensors, monitoring platforms, and web tools. We provide real-time visibility of temperature variations to prevent risks and ensure the quality of the medication at all times.",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/eq41.png"),
            icon: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/Asset Tracker _ EQ 41.png"),
            left: true,
          },
          {
            titulo: "Monitoring Portal",
            subtitulo:
              "We contribute to operational efficiency by consolidating records and automating reports, replacing manual work and facilitating the delivery of reports for effective control.",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/portal_monitoreo.jpg"),
            icon: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/Portal de Monitoreo.png"),
            left: false,
          },
        ],
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/servicios/monitoreo_de_productos_refrigerados/PTC-ESP.pdf"),
        },
      ],
    },
    monitoreo_de_activos_indoor: {
      lateral: {
        icono: require("../assets/img/servicios/monitoreo_de_activos_indoor/ActivosIndoor.png"),
        titulo: "Indoor Asset Monitoring",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitoreo_de_activos_indoor/finder.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Asset Tracking",
            subtitulo:
              "We support the location and tracking of remote indoor assets through  WIFI geolocation, sending alarms in case  changes of location and tilt.The Tracking information is transmitted through a LTE network to our Platform. ",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_activos_indoor/asset_tracker.png"),
            icon: require("../assets/img/servicios/monitoreo_de_activos_indoor/EQ42.png"),
            left: true,
          },
          {
            titulo: "SOFTEL Platform",
            subtitulo:
              "We conduct agile and efficient data analysis through a management portal that enables asset location and alarms visualization.",
            points: [],
            imagen: require("../assets/img/servicios/monitoreo_de_activos_indoor/gps-01.png"),
            icon: require("../assets/img/servicios/monitoreo_de_activos_indoor/plataforma softel.png"),
            left: false,
          },
        ],
      },
      descargas: [
        { titulo: "Brochure", documento: require("../assets/img/servicios/monitoreo_de_activos_indoor/FDR-ESP.pdf") },
      ],
    },
    enlaces_monitoreados_de_comunicacion_via_celular: {
      lateral: {
        icono: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/ComunicacionCelular.png"),
        titulo: "Monitored Cellular Communication Connections",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/softel-max.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "High Availability Communication Service",
            subtitulo:
              "We offer a managed, turnkey service with national coverage for connecting remote points through redundant cellular links that utilize the best two networks in the site  (Telcel, Movistar, AT&T, and Altán). The service includes the router equipment (MSC-4G), link monitoring platform, corrective maintenance, and 24/7 help desk support.",
            points: [],
            imagen: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/comunicacion.jpg"),
            icon: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/señal.png"),
            left: true,
          },
          {
            titulo: "SOFTEL Border Router",
            subtitulo:
              "A router manufactured by SOFTEL that can use any communication protocol for monitoring physical and logical variables. Communication is done through cellular networks with redundancy, ensuring 99.5% availability. Installation can be completed within a maximum of 72 hours without the need for site modifications or wiring.",
            points: [],
            imagen: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/router.jpg"),
            icon: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/router.png"),
            left: false,
          },
          {
            titulo: "SOFTEL Monitoring Platform",
            subtitulo:
              "A web platform for real-time 24/7 visualization of the status of communication links, considering availability, latency, packet loss, and consumed traffic. Usage licenses are available with unlimited users.",
            points: [],
            imagen: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/monitoreo.jpg"),
            icon: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/monitoreo.png"),
            left: true,
          },
        ],
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/servicios/enlaces_monitoreados_de_comunicacion_via_celular/M&G-CPR-ESP (1).pdf"),
        },
      ],
    },
    monitore_entornos_atms: {
      lateral: {
        icono: require("../assets/img/servicios/monitore_entornos_atms/EntornoAtms.png"),
        titulo: "ATM Environment Monitoring",
        img: [],
        descrgas: true,
      },
      contenido: {
        //img_principal: require("../assets/img/servicios/monitore_entornos_atms/softel_guard.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Environment control",
            subtitulo:
              "We provide a solution that allows real-time monitoring of the status of various components within the environment of an ATM, kiosk, or site. This is achieved through the installation of optical sensors that detect door openings, a temperature probe, and an accelerometer for motion detection. It is also possible to incorporate any other electronic sensor available in the market, in addition to having deterrent alarms in case of detecting invasive elements.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/monitoreo_entorno.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/sensor.png"),
            left: true,
          },
          {
            titulo: "SOFTEL Border Router",
            subtitulo:
              "The service offered involves the installation of a router device (MSC-4G), to which the sensors are connected to process and transmit the data. The router developed by SOFTEL has the capability to perform routing using any protocol that enables monitoring of physical and logical variables. It is an independent communication device that operates on two cellular networks and ensures a 99.5% availability for transmitting alarms.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/router.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/router.png"),
            left: false,
          },
          {
            titulo: "SOFTEL Dashboard",
            subtitulo:
              "A web-based control panel with unlimited user access is provided for real-time alarm visualization. Historical reports per component are available, as well as the ability to define which alarms are automatically closed and which require intervention. Integration with client systems is possible via web services.",
            points: [],
            imagen: require("../assets/img/servicios/monitore_entornos_atms/dashboard.jpg"),
            icon: require("../assets/img/servicios/monitore_entornos_atms/monitoreo.png"),
            left: true,
          },
        ],
      },
      descargas: [
        { titulo: "Brochure", documento: require("../assets/img/servicios/monitore_entornos_atms/M&G-CPR-ESP.pdf") },
      ],
    },
  },
  productos: {
    productos_iot: {
      lateral: {
        icono: require("../assets/img/productos/productos_iot/IoT.png"),
        titulo: "Productos IoT",
        img: [],
        descrgas: false,
      },
      contenido: {
        //img_principal: require("../assets/img/productos/productos_iot/things.m4v"),
        descripcion: "",
        secciones: [
          {
            titulo: "Diseño a la medida",
            subtitulo:
              "SOFTEL es una casa de diseño de tecnología IoT. Acompañamos a nuestros clientes durante el desarrollo y la vida útil de sus productos, adecuándolos a las necesidades del mercado. Contamos con 20 años de experiencia desarrollando dispositivos que operan con la menor intervención humana y con algoritmos que garantizan mantenerlos en línea todo el tiempo",
            points: [],
            imagen: require("../assets/img/productos/productos_iot/Diseño a la medida.jpg"),
            icon: require("../assets/img/productos/productos_iot/diseño_medida.png"),
            left: true,
          },
          {
            titulo: "Arquitecturas IoT",
            subtitulo:
              "Apoyamos a nuestros clientes a encontrar y definir la forma óptima para que sus datos fluyan desde el punto remoto hasta el centro de datos corporativo en sitio o en la nube y culminen en su almacenamiento, procesamiento y análisis. Con tecnología de punta, nuestros dispositivos toman decisiones autónomas y transmiten únicamente datos de valor a través de nuestras concentradores (Edge Computing), todo esto soportado en años de experiencia tecnológica en comunicaciones.",
            points: [],
            imagen: require("../assets/img/productos/productos_iot/Arquitecturas IoT.jpg"),
            icon: require("../assets/img/productos/productos_iot/diseño_medida.png"),
            left: false,
          },
          {
            titulo: "Integración a sistemas",
            subtitulo:
              "La infraestructura y soluciones de SOFTEL permiten a nuestros clientes conectar sensores, dispositivos, aplicativos o software de gestión, apoyándolos con API’s, Web Services, y protocolos de información que se definen para la interacción entre los diferentes sistemas. Además, garantizamos la integración con plataformas como SAP, Google, AWS y Microsoft, entre otras.",
            points: [],
            imagen: require("../assets/img/productos/productos_iot/Integración a sistemas.jpg"),
            icon: require("../assets/img/productos/productos_iot/ArqIoT.png"),
            left: true,
          },
        ],
      },
    },
    control_refrigeracion_easy_cool: {
      lateral: {
        icono: require("../assets/img/productos/control_refrigeracion_easy_cool/EasyCool.png"),
        titulo: "EASYCOOL® Refrigeration Control",
        titulo: (
          <>
            <Row>
              <Col md="12">EASYCOOL®</Col>
              <Col md="12">Refrigeration Control</Col>
            </Row>
            {/* <div>EASYCOOL®</div>
            <div> Refrigeration Control</div> */}
          </>
        ),
        img: [],
        descrgas: true,
      },
      descargas: [
        {
          titulo: "Brochure",
          documento: require("../assets/img/productos/control_refrigeracion_easy_cool/EasyCool_AppBrochure_ES.pdf"),
          left: true,
          text: "Download PDF",
        },
        { titulo: "Google Play", documento: "", left: false, text: "Download for Android" },
        { titulo: "User's manual", documento: "", left: true, text: "Download PDF" },
        { titulo: "App Store", documento: "", left: false, text: "Download for Apple" },
        //{ titulo: "Manufacturer's manual", documento: "", left: true, text: "Download pdf" },
      ],
    },
    servicios_diseño: {
      lateral: {
        icono: require("../assets/img/productos/servicios_diseño/icon_servicios_diseño.png"),
        titulo: "Design Services",
        img: [],
        descargas: false,
      },
    },
  },
};

export default detalle;
